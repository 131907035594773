import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'detailsAssetItems',
  components: {
    appStrings,
    DatePicker,
    commonHelper
  },
  props: ['rowData','saveFlag','getassetMasterTags'],
  data() {
    return {
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      payload: null,
      detailsAssetMasterTagsData: [
        {
         tag_code:null,
         tag_name:null,
         active:false,
        }
      ],
      detailsAssetMasterTagsFields: [
        // {
        //   key: 'add',
        //   stickyColumn: true
        // },
        // {
        //   key: 'remove',
        //   stickyColumn: true
        // },
        {
            key:'tag_code'
        },
        {
          key: 'tag_name'
        },
        {
            key: 'active',
            label:'Status'
        },
      ],
      showValueSetModal: false,
      editMode: false,
      nameFlag: false,
      active: true
    };
  },
  mounted() {
    this.getTagsData(this.rowData);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          //   this.showHideAddFaLocationModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
        } else if (actionName === 'save') {
          if(this.saveFlag){
            this.postassetMasterTags()
          }else{
            this.putassetMasterTags()
          }
        }
      }
    });
  },
  methods: {
    getTagsData(rowData) {
      if (rowData) {
        this.detailsAssetMasterTagsData=[]
        this.nameFlag =false;
        this.detailsAssetMasterTagsData.push(rowData)       
      } else {
        this.editMode = true;
        this.nameFlag =true;
      }
    },
    postassetMasterTags() {
      if (this.detailsAssetMasterTagsData[0].tag_name && this.detailsAssetMasterTagsData[0].tag_code) {
    // this.detailsAssetMasterTagsData.map(item=>{
    //   if(item.active=="ACTIVE"){
    //     item.active=true
    //   }else{
    //     item.active=false
    //   }
    // })
      const payload = {
        active: this.detailsAssetMasterTagsData[0].active,
        tag_code: this.detailsAssetMasterTagsData[0].tag_code,
        tag_id: 0,
        tag_name: this.detailsAssetMasterTagsData[0].tag_name,
      };
      // this.detailsAssetMasterTagsData.map(item=>{
      //   if(item.active==true){
      //     item.active="ACTIVE"
      //   }else{
      //     item.active="INACTIVE"
      //   }
      // });
      this.loader = true;
      this.$store
        .dispatch('assets/postAssetMasterTag', payload)
        .then(resp => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg=resp.data.message;
            this.getassetMasterTags()
          }
          else {
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
      }
      else{
        alert("Either Tag Code or Tag Name is missing")
      }
    },
    putassetMasterTags() {
      // this.detailsAssetMasterTagsData.map(item=>{
      //   if(item.active==="ACTIVE"){
      //     item.active=true
      //   }else{
      //     item.active=false
      //   }
      // })
        const payload = {
            active: this.detailsAssetMasterTagsData[0].active,
            tag_code: this.detailsAssetMasterTagsData[0].tag_code,
            tag_id: this.rowData.tag_id,
            tag_name: this.detailsAssetMasterTagsData[0].tag_name,
        };
        // this.detailsAssetMasterTagsData.map(item=>{
        //   if(item.active===true){
        //     item.active="ACTIVE"
        //   }else{
        //     item.active="INACTIVE"
        //   }
        // });
        this.loader = true;
        this.$store
          .dispatch('assets/putAssetMasterTag', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert=true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.responseMsg=resp.data.message;
              this.getassetMasterTags()
            }
            else {
                this.isSuccess = false;
                this.responseMsg = resp.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        
      },
  

    // addNewRow() {
    //     this.detailsAssetMasterItemData.push({
    //       item_filters: null,
    //       status: 'ACTIVE'
    //     });
    // },

    // removeRow(index) {
    //     this.detailsAssetMasterItemData.splice(index, 1);
    // },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_VSET) {
        this.detailsAssetMasterTagsData[this.specIndex].active =
          item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
