import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import detailsAssetMasterTags from "./detailsAssetMasterTags"

export default {
  name: 'assetTags',
  components: {
    appStrings,
    DatePicker,
    detailsAssetMasterTags
  },
  watch: {
    currentPage: function() {
      this.getassetMasterTags();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getassetMasterTags();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showAddFaLocationModal: false,
      assetTagCode:null,
      assetTagName:null,
      showHideAssetMasterTagsFlag: false,
      responseMsg: '',
      isSuccess: false,
      assetTagsData: [],
      assetTagsFields: [
        {
          key: 'tag_code',
        },
        {
          key: 'tag_name',
        },
        {
          key: 'active',
          label:'Status'
        },
        {
          key: 'created_by_user',
          label:"Created By"
        },
        {
          key:'creation_date'
        }
      ],
      showAlert: false,
      showValueSetModal: false,
      active:true,
      rowData:null,
      saveFlag:false,
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData=null,
          this.saveFlag=true;
          this.showHideAssetMasterTagsModal(true);
        } 
      }
    });
  },
  methods: {
    getassetMasterTags(){
      const payload = {
        // tagId:this.assetTagsData.tag_id,
        page: this.currentPage - 1,
        limit: this.perPage,
        tag_code:this.assetTagCode,
        tag_name: this.assetTagName,
        active:this.active
       };
       this.loader = true;
       this.$store
         .dispatch('assets/getAssetMasterTag', payload)
         .then(resp => {
           this.loader = false;
           if (resp.status === 200) {
             this.assetTagsData = resp.data.data.page;
             this.totalRows = resp.data.data.total_elements;
            //  this.assetTagsData.map(item=>{
            //   if(item.active==true){
            //     item.active="ACTIVE"
            //   }else{
            //     item.active="INACTIVE"
            //   }
            // })
           }
           else {
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    rowSelected(item) {
      this.rowData=item;
      this.saveFlag=false;
      this.showHideAssetMasterTagsFlag=!this.showHideAssetMasterTagsFlag;
    },
    showHideAssetMasterTagsModal(flag){
      this.showHideAssetMasterTagsFlag = flag;
    },
    clearUser() {
      this.assetTagCode=null,
      this.assetTagName=null,
      this.totalRows = null;
      this.currentPage = 1;
      this.assetTagsData=null;
      this.active=false;
    },
},
beforeDestroy() {
  this.unsubscribe();
}
};
